<template>

<app-wrapper class="banner" v-bgimage="$state.page.banner_image">

	<app-wrapper :content="true" class="banner-content">
	
		<h2>{{ $state.page.banner_title }}</h2>

	</app-wrapper>

	<div class="banner-scroll" v-if="window.is.desktop" />

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.banner {
	height: calc(100vh - 97px);
	background-position: 50% 50%;
	background-size: cover;
}

.is-mobile .banner {
	height: calc(100vh - 80px);
}

.banner-content {
	height: 100%;
	display: flex;
	align-items: center;
}

.is-tablet .banner-content {
	align-items: flex-start;
	padding-top: 64px;
}

.is-mobile .banner-content {
	align-items: flex-start;
	padding-top: 40px!important;
}

.banner-content h2 {
	font-size: 64px;
	line-height: 84px;
	text-align: left;
	color: #fff;
	font-weight: 300;
	text-shadow: 0px 3px 10px #0000005F;
	width: 470px;
}

.is-tablet .banner-content h2 {
	font-size: 36px;
	line-height: 48px;
	width: 280px;
}

.is-mobile .banner-content h2 {
	font-size: 28px;
	line-height: 32px;
	width: 170px;
}

.banner-scroll {
	position: absolute;
	bottom: 0px;
	left: 50%;
	width: 80px;
	height: 80px;
	margin-left: -40px;
	background-color: #BBD9CD;
	background-image: url(~@/assets/home.scroll.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

</style>
